<template>
  <input
    v-model="attribute"
    @change="addEditDelSelectFilter(item.id, typeCheck, item.name)"
    type="checkbox"
    class="custom-checkbox-test"
    :id="typeCheck + item.id"
  /><label
    :for="typeCheck + item.id"
    class="font-nexa text-agrogo-gray-1 text-left text-sm xl:text-base mt-1 sm:mt-0 hover:text-agrogo-yellow-1"
    >{{ item.name }}</label
  >
</template>

<script>
export default {
  emits: ["addEditDelSelectFilter"],
  props: {
    item: {
      type: Object,
      default: null,
    },
    typeCheck: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      attribute: me.item.state,
    };
  },
  watch: {
    ["item.state"]() {
      const me = this;
      me.attribute = me.item.state;
    },
  },
  methods: {
    addEditDelSelectFilter(id, type, name) {
      const me = this;
      me.$emit("addEditDelSelectFilter", id, type, name);
    },
  },
};
</script>

<style></style>

<template>
  <Transition name="slide-fade">
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isModal"
    >
      <div
        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-black-trasparent transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <Transition name="slide-fade">
          <div
            class="relative bg-transparent inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            v-if="isModal"
          >
            <div class="flex justify-end content-button-ModalShare">
              <button
                type="Button"
                class="btn-circle-ModalShare absolute"
                @click.prevent="() => $emit('closeModal')"
              >
                <span
                  class="icon-x text-xs md:text-base text-agrogo-gray-1"
                ></span>
              </button>
            </div>
            <section class="bg-agrogo-green-2 p-5">
              <div
                class="font-nexa font-normal text-white text-left text-sm space-x-2 md:text-2xl md:space-x-4"
              >
                <span
                  class="icon-share text-agrogo-yellow-1 leading-none"
                ></span
                ><span class="font-black">Compartir </span>Link de busqueda
              </div>
              <!-- <div class="content-link">
                <span>
                  {{ link }}
                </span>
              </div> -->
              <div class="px-1 lg:px-8 md:px-6 mt-4">
                <div class="flex items-center justify-between">
                  <button
                    @click.prevent="emailWhatsapp('1')"
                    class="font-nexa text-xs text-white md:text-lg"
                    :class="{ 'text-agrogo-yellow-1': selectWhatsapp }"
                  >
                    <span class="icon-whatsapp pr-1.5 md:pr-3"></span>Whatsapp
                  </button>
                  <button
                    @click.prevent="emailWhatsapp('2')"
                    class="font-nexa text-xs text-white md:text-lg"
                    :class="{ 'text-agrogo-yellow-1': selectEmail }"
                  >
                    <span class="icon-mail pr-1.5 md:pr-3"></span>Email
                  </button>
                </div>
                <div class="flex items-center justify-between" />
                <div class="flex flex-col pt-4 pb-7">
                  <!-- Whatsapp -->
                  <div v-if="selectWhatsapp">
                    <div class="flex justify-between md:justify-start">
                      <div class="relative w-6/12 md:w-3/12 mr-2">
                        <img :src="img" class="img-code" />
                        <v-select
                          :options="codes"
                          v-model="selected"
                          :reduce="(option) => option.id"
                          class="style-chooser"
                          label="phone_code"
                        >
                          <template
                            #option="{ phone_code, path_img_iso_large, name }"
                          >
                            <div class="flex items-center justify-between">
                              <div class="flex">
                                <img
                                  :src="path_img_iso_large"
                                  :alt="phone_code"
                                  class="mr-2"
                                />
                                <div class="font-medium">{{ phone_code }}</div>
                              </div>
                              <div class="font-medium">
                                <div v-if="name.length > 20">
                                  {{ name.slice(0, 20) }}..
                                </div>
                                <div v-else>{{ name }}</div>
                              </div>
                            </div>
                          </template>
                          <template #no-options>Sin opciones</template>
                        </v-select>
                      </div>
                      <div class="relative w-7/12 md:w-9/12">
                        <div
                          class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                        >
                          <span
                            class="icon-telefono text-agrogo-black-1 text-lg"
                          ></span>
                        </div>
                        <input
                          v-model="v$.formData.telephone.$model"
                          @blur="v$.formData.telephone.$touch()"
                          id="telephone"
                          name="telephone"
                          placeholder="3161234568"
                          type="text"
                          class="input-login"
                          :class="{
                            'error-form': v$.formData.telephone.$error,
                          }"
                        />
                      </div>
                    </div>
                    <p
                      class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                      v-if="v$.formData.telephone.$error"
                    >
                      {{ messageValidation(v$.formData.telephone) }}
                    </p>
                    <p
                      class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                      v-else
                    >
                      &nbsp;
                    </p>
                  </div>

                  <!-- Email -->
                  <div v-else>
                    <div class="relative">
                      <div
                        class="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none"
                      >
                        <span
                          class="icon-mail text-agrogo-black-1 text-lg"
                        ></span>
                      </div>
                      <input
                        v-model="v$.formData.email.$model"
                        @blur="v$.formData.email.$touch()"
                        id="email"
                        name="email"
                        placeholder="agrogo@agrogo.com"
                        type="email"
                        class="input-login"
                        :class="{ 'error-form': v$.formData.email.$error }"
                      />
                    </div>
                    <p
                      class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                      v-if="v$.formData.email.$error"
                    >
                      {{ messageValidation(v$.formData.email) }}
                    </p>
                    <p
                      class="text-xs text-agrogo-yellow-2 font-nexa block ml-2 mb-1 text-left mt-1"
                      v-else
                    >
                      &nbsp;
                    </p>
                  </div>

                  <!-- CONTENEDOR DE BOTÓN COMPARTIR FINCA -->
                  <div class="w-full mr-2">
                    <button
                      type="button"
                      :disabled="v$.$invalid"
                      class="btn-login w-full mt-4"
                      @click.prevent="send"
                    >
                      <span class="font-nexa font-bold">Compartir</span>
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import validationMixin from "@/mixins/validationMixin";
// vuelidate
import { required, minLength, maxLength, email } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  inject: ["provider"],
  mixins: [validationMixin],
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      repository: me.provider.authRepository,
      formData: {
        telephone: "",
        email: "",
      },
      codes: [],
      img: null,
      selected: null,
      selectWhatsapp: true,
      selectEmail: false,
      code_area_id: 1,
    };
  },
  watch: {
    selected(id) {
      const me = this;
      me.code_area_id = id;
      me.loadImg(me.code_area_id);
    },
  },
  validations() {
    const me = this;

    return me.selectWhatsapp
      ? {
          formData: {
            telephone: {
              required,
              minLength: minLength(7),
              maxLength: maxLength(15),
            },
          },
        }
      : {
          formData: {
            email: {
              required,
              email,
            },
          },
        };
  },
  methods: {
    emailWhatsapp(type) {
      const me = this;

      if (type == "1") {
        me.selectWhatsapp = true;
        me.selectEmail = false;
        me.formData.email = "";
      } else {
        me.selectWhatsapp = false;
        me.selectEmail = true;
        me.formData.telephone = "";
      }
    },
    //CARGA LOS CODIGOS DE AREA
    async loadCodeArea() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAdicional = await generateHash();
        const { data } = await me.repository.getAll(dataAdicional);

        data.data.forEach((el) => {
          el.phone_code = `+${el.phone_code}`;
          el.path_img_iso_medium = `${API_ROUTES.image.get}${el.path_img_iso_medium}`;
          el.path_img_iso_large = `${API_ROUTES.image.get}${el.path_img_iso_large}`;
        });
        me.codes = data.data;
        me.code_area_id = 44;
        me.selected = 44;
        me.loadImg(me.code_area_id);
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
    //CARGA LA URL COMPLETA DE LA IMAGEN
    loadImg(id) {
      const me = this;
      const code = me.codes.find((el) => el.id == id);
      me.img = code.path_img_iso_large;
    },
    send() {
      const me = this;
      let a = document.createElement("a");
      let link2 = me.link.split("&").join("%26");
      if (me.selectWhatsapp) {
        const code = me.codes.find((el) => el.id == me.code_area_id);
        const phone_code = code.phone_code.split("+").join("");
        const link = `https://api.whatsapp.com/send?phone=${phone_code}${me.formData.telephone}&text=Mira%20esta%20finca:%20${link2}`;
        a.href = link;
        a.target = "_blank";
        a.click();
      } else {
        const link = `mailto:${me.formData.email}?Subject=Mira%20esta%20finca&body=${link2}`;
        a.href = link;
        a.target = "_blank";
        a.click();
      }

      me.formData.email = "";
      me.formData.telephone = "";
      me.v$.formData.$reset();
    },
  },
  mounted() {
    const me = this;
    me.loadCodeArea();
  },
};
</script>

<style></style>

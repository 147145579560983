<template>
  <p
    class="font-nexa font-normal text-sm text-white pl-8 text-left bg-agrogo-green-1 pt-2.5 md:text-2xl md:px-24"
  >
    <span class="font-black">Tipo de</span> Finca
  </p>
  <div
    class="flex w-auto h-auto bg-agrogo-green-1 pb-5 pt-1.5 md:px-7 md:pb-0 justify-center"
  >
    <div
      class="items-center px-2 pt-5 overflow-x-auto flex space-x-1 md:space-x-3.5 md:py-0 md:pt-2 hide-scrollbar"
    >
      <div
        v-for="(item, index) in category.subcategories"
        :key="index"
        @click.prevent="() => $emit('close')"
      >
        <router-link
          class="card-slider w-20 h-16 overflow-hidden flex flex-col md:w-32 md:h-24 text-agrogo-gray-1 hover:text-agrogo-yellow-1"
          :to="{
            name: 'ListOfFarms',
            query: {
              buscar: `${item.name
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()}-${item.id}`,
            },
          }"
        >
          <span
            class="text-4xl text-agrogo-yellow-1 mb-2 md:text-5xl"
            :class="item.icon"
          ></span>
          <div class="font-nexa font-normal mb-2 text-xs md:text-base">
            {{ item.name }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "SliderTypeFarm",
  computed: {
    ...mapState("farmTypes", ["category"]),
  },
  methods: {
    searchFarm(item) {
      const me = this;
      me.$router.push({
        name: "ListOfFarms",
        query: {
          buscar: `${item.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()}-${item.id}`,
        },
      });
      me.$emit("close");
    },
  },
};
</script>

<style></style>

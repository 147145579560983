import { generateHash } from "@/utils";
import { mapState, mapActions } from "vuex";

export default {
  inject: ["provider"],
  data() {
    const me = this;
    return {
      repository: me.provider.leadRepository,
    };
  },
  computed: {
    ...mapState("leadSteps", [
      "leadStep1",
      "leadStep2",
      "leadStep3",
      "isSaveCaptaction",
    ]),
  },
  methods: {
    ...mapActions("leadSteps", ["saveCaptaction"]),
    async saveCaptactionLead() {
      const me = this;
      let area1 = null;
      let area2 = null;
      let subcategories_id = [];
      let municipality = [];
      const formData = { ...me.leadStep1, ...me.leadStep2, ...me.leadStep3 };
      area1 = formData.area_min;
      area2 = formData.area_max;
      formData.price_max = Number(
        formData.price_max.split("$")[1].split(".").join("")
      );
      formData.price_min = Number(
        formData.price_min.split("$")[1].split(".").join("")
      );
      if (formData.area_min && formData.area_min.split(".")[1]) {
        area1 = formData.area_min.split(".").join("");
      }

      if (formData.area_min && formData.area_min.split(",")[1]) {
        area1 = formData.area_min.split(".").join("").split(",").join(".");
      }

      if (formData.area_max && formData.area_max.split(".")[1]) {
        area2 = formData.area_max.split(".").join("").split(",").join(".");
      }

      if (formData.area_max && formData.area_max.split(",")[1]) {
        area2 = formData.area_max.split(".").join("").split(",").join(".");
      }

      formData.area_min = parseFloat(area1);
      formData.area_max = parseFloat(area2);
      formData.code_id = formData.code_area_id;
      formData.department = formData.departmentId.name;

      formData.subcategories.forEach((el) => {
        subcategories_id.push(el.id);
      });

      formData.municipality_id.forEach((el) => {
        municipality.push(el.name);
      });

      formData.subcategories_id = subcategories_id;
      formData.municipality = municipality;
      formData.link_property = window.location.href;

      try {
        const dataAditional = await generateHash();
        delete formData.departmentId;
        await me.repository.save({
          ...dataAditional,
          ...formData,
        });
        me.saveCaptaction(false);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    const me = this;
    if (me.isSaveCaptaction) {
      me.saveCaptactionLead();
    }
  },
};
